import React from 'react';

export const ArrowLeftIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="11"
    viewBox="0 0 6 11"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M983.993,700.757l5.155,5.251,0.859-.876-4.548-4.632,4.548-4.632-0.859-.876-5.155,5.252L984,700Z"
      transform="translate(-984 -695)"
    />
  </svg>
);
