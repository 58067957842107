import React from 'react';

export const ArrowRightIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="6"
    height="11"
    viewBox="0 0 6 11"
    style={{
      fillRule: 'evenodd',
    }}
    {...props}
  >
    <path
      d="M1016.01,700.757l-5.16,5.251-0.86-.876,4.55-4.632-4.55-4.632,0.86-.876,5.16,5.252L1016,700Z"
      transform="translate(-1010 -695)"
    />
  </svg>
);
